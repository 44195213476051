<template>
  <el-dialog
    :title="'Confirm Incoming Actual Payable Summary ' + row.summaryNumber"
    :visible.sync="isShow"
    width="50%"
  >

    <div>
      Accrual Billing Period:
      <el-select v-model="row.accrualBillingPeriod">
        <el-option v-for="item in sunCurrencyProfileBillingPeriods" :key="item" :label="item" :value="item"></el-option>
      </el-select>
    </div>


    <div class="w-100 d-flex justify-content-end">
      <el-button type="primary" class="ml-1" @click="confirmSubmit(row)">Confirm</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { isAccrualEnabled } from "@/views/erp/utils/system";
import axios from "axios";
import apis from "@/configs/apis";
import _ from "lodash";
import Prompt from "@/views/erp/mixins/Prompt";
import { EventBus } from "@/views/erp/utils/event-bus";

export default {
  mixins: [Prompt],
  data() {
    return {
      isShow: false,
      row: {},
      sunCurrencyProfileBillingPeriods: [],
    }
  },
  mounted() {
    this.loadSunCurrencyProfileItems();
  },
  methods: {
    isAccrualEnabled,
    confirm(row) {
      this.row = row;
      this.isShow = true;
    },

    async confirmSubmit(row) {
      return axios.post(apis.erpIncomingActualPayableSummary + '/' + row.id + '/confirm', row)
        .then(response => {
          this.isShow = false;
          this.promptInfo('Incoming Actual Payable Summary Confirmed');
          EventBus.$emit("summary-updated");
        })
        .catch(e => {
          console.log(e);
          this.promptError(e);
        }).finally(() => {
          this.isShowConfirmAccruals = false;
        });
    },

    loadSunCurrencyProfileItems() {
      axios.get(
        apis.erpGetMasterData + "/CURRENCY_PROFILE/SUN",
      ).then(response => {
        axios.get(apis.erpCurrencyProfileItems, {params: {currencyProfileId: response.value}})
          .then(response => {
            let billingPeriods = [];
            response.data.data.data.forEach(item => {
              billingPeriods.push(item.billingPeriod);
            });

            this.sunCurrencyProfileBillingPeriods = _.uniq(billingPeriods).sort().reverse();
          })
      });
    },
  }
}
</script>
