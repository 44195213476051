<template>
  <div>
      <AdvanceTable ref="advanceTable" :url="apis.erpIncomingActualPayableSummary" :height="window.height - 310" tableSize="sm" :columns="columns" :selectable="true" @row-selected="onRowSelected" v-loading="isLoading">
        <template #title>
            {{ $t("API Actual Payable Summary") }}
        </template>
        <template #button>
          <b-button variant="secondary" size="sm" class="mr-50" @click="create">
            <feather-icon icon="PlusIcon"/>
            <span class="align-middle">{{ $t("Create Manually") }}</span>
          </b-button>

          <b-button variant="secondary" size="sm" class="mr-50" @click="openModalForUpload">
            <feather-icon icon="UploadIcon"/>
            <span class="align-middle">{{ $t("Import Mapping Excel") }}</span>
          </b-button>

          <b-button variant="secondary" size="sm" class="mr-50" @click.exact="exportExcel('EXCEL')">
            <feather-icon icon="DownloadIcon"/>
            <span class="align-middle">{{ $t("Export Excel") }}</span>
          </b-button>
        </template>
        <template #cell(originalAccrualAmount)="row">
          {{ toFixed(row.item.accrualAmount, 2) }} {{ row.item.accrualAmountCurrency }}
          <div class="text-muted" v-if="row.item.originalAccrualAmountCurrency !== row.item.accrualAmountCurrency">{{ toFixed(row.item.originalAccrualAmount, 2) }} {{ row.item.originalAccrualAmountCurrency }}</div>
        </template>
        <template #cell(originalActualAmount)="row">
          {{ toFixed(row.item.actualAmount, 2) }} {{ row.item.actualAmountCurrency }}
          <div class="text-muted" v-if="row.item.originalActualAmountCurrency !== row.item.actualAmountCurrency">{{ toFixed(row.item.originalActualAmount, 2) }} {{ row.item.originalActualAmountCurrency }}</div>
        </template>
        <template #cell(varianceAmount)="row">
          {{ toFixed(row.item.varianceAmount, 2) }} {{ row.item.varianceAmountCurrency }}
        </template>
        <template #cell(unconfirmedAmount)="row">
          {{ toFixed(row.item.unconfirmedAmount, 2) }} {{ row.item.unconfirmedAmountCurrency }}
        </template>
        <template #cell(addTime)="row">
          {{ formatWithCurrentTimezone(row.item.addTime) }}
        </template>
        <template #cell(confirmTime)="row">
          {{ formatWithCurrentTimezone(row.item.confirmTime) }}
        </template>
        <template #cell(selection)="row">
          <input type="checkbox" :checked="row.rowSelected" style="pointer-events: none"/>
        </template>
        <template #head(selection)="row">
          <input type="checkbox" style="margin-top: 40px" ref="selectAll" @click="onSelectAllSelected">
        </template>
        <template #cell(actions)="row">
          <el-link @click.stop="edit(row.item)">
            <i class="el-icon-edit"></i>
            Edit
          </el-link>

          <el-link @click.stop="link(row.item)">
            <i class="el-icon-link"></i>
            Accrual Mapping
            <span v-if="false && row.item.accrualAssoc.length > 0">({{ row.item.accrualAssoc.length }})</span>
          </el-link>

          <el-link @click.stop="confirm(row.item)" v-if="row.item.confirmTime === null">
            <i class="el-icon-check"></i>
            Confirm
          </el-link>

          <el-link icon="el-icon-search" @click="showAudits(row.item)">{{$t('Audits')}}</el-link>
        </template>
      </AdvanceTable>

    <el-dialog
      class="compact"
      :title="$t(showAction(action) + ' ' + summary.summaryNumber)"
      :visible.sync="isShowDialog"
      width="40%"
      v-loading="isLoading"
    >
      <div v-for="assoc in summary.accrualAssocs">
        <el-alert class="mb-2" title="Editing this summary will reset Applied Amount to full amount." type="info" :closable="false" v-if="assoc.appliedAmount !== summary.actualAmount"/>
      </div>

      <b-form-group :label="$t('Summary Code')" v-if="false">
        <el-input v-model="summary.summaryNumber" size="mini" :disabled="summary.confirmTime !== null"/>
      </b-form-group>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('System Code') }}
        </b-col>
        <b-col lg="8">
          <el-input v-model="summary.systemCode" size="mini" :disabled="summary.confirmTime !== null"/>
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Account Code') }}
        </b-col>
        <b-col lg="8">
          <el-input v-model="summary.accountCode" size="mini" :disabled="summary.confirmTime !== null"/>
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Customer Name') }}
        </b-col>
        <b-col lg="8">
          <el-input v-model="summary.customerName" size="mini" :disabled="summary.confirmTime !== null"/>
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Billing Period') }}
        </b-col>
        <b-col lg="8">
          <el-input v-model="summary.billingPeriod" size="mini" :disabled="summary.confirmTime !== null"/>
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Accrual Billing Period') }}
        </b-col>
        <b-col lg="8">
          <el-input v-model="summary.accrualBillingPeriod" size="mini" :disabled="true"/>
        </b-col>
      </b-row>


      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Vendor Name') }}
        </b-col>
        <b-col lg="8">
          <el-input v-model="summary.vendorName" size="mini" :disabled="summary.confirmTime !== null"/>
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Charge Code') }}
        </b-col>
        <b-col lg="8">
          <el-input v-model="summary.chargeCode" size="mini" :disabled="summary.confirmTime !== null"/>
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Destination') }}
        </b-col>
        <b-col lg="8">
          <el-input v-model="summary.countryCode" size="mini" :disabled="summary.confirmTime !== null"/>
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Accrual Billing Number') }}
        </b-col>
        <b-col lg="8">
          <el-input type="textarea" v-model="summary.accrualBillingNumber" size="mini" :disabled="summary.confirmTime !== null"  :autosize="{ minRows: 2, maxRows: 5}"/>
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Actual Billing Number') }}
        </b-col>
        <b-col lg="8">
          <el-input type="textarea" v-model="summary.actualBillingNumber" size="mini" :disabled="summary.confirmTime !== null"  :autosize="{ minRows: 2, maxRows: 5}"/>
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Accrual Amount') }}
        </b-col>
        <b-col lg="6">
          <el-input v-model="summary.originalAccrualAmount" size="mini" :disabled="summary.confirmTime !== null"/>
        </b-col>
        <b-col lg="2">
          <el-select v-model="summary.originalAccrualAmountCurrency" size="mini" :disabled="summary.confirmTime !== null">
            <el-option v-for="item in sunCurrencyProfileCurrencies" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Actual Amount') }}
        </b-col>
        <b-col lg="6">
          <el-input v-model="summary.originalActualAmount" size="mini" :disabled="summary.confirmTime !== null"/>
        </b-col>
        <b-col lg="2">
          <el-select v-model="summary.originalActualAmountCurrency" size="mini" :disabled="summary.confirmTime !== null">
            <el-option v-for="item in sunCurrencyProfileCurrencies" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Reference Number') }}
        </b-col>
        <b-col lg="8">
          <el-input v-model="summary.referenceNumber" size="mini" :disabled="summary.confirmTime !== null"/>
        </b-col>
      </b-row>

      <div class="mt-half">
        <div class="w-100 d-flex justify-content-end">
          <b-button variant="primary" class="btn" @click="submit()" style="text-transform: capitalize" v-if="summary.confirmTime === null">
            {{ $t(action.toLowerCase()) }}
          </b-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      class="compact"
      :title="$t('Accrual Link -  ' + summary.summaryNumber)"
      :visible.sync="isShowLink"
      width="90%"
      v-loading="isLoading"
    >
      <p>
        Actual Amount: {{ summary.actualAmount }} {{ summary.actualAmountCurrency }}
      </p>

      <table class="table">
        <thead>
         <tr>
           <th>Accrual Number</th>
           <th>Accrual Amount</th>
           <th>Accrual Total Actual Amount</th>
           <th>Applied Actual Amount</th>
           <th>Billing Numbers</th>
           <th>Map Date</th>
           <th>Actions</th>
         </tr>
        </thead>
        <tbody>
          <tr v-for="accrualLink in accrualLinks">
            <td>{{ accrualLink.accrual.accrualNumber }}</td>
            <td>
              {{ toFixed(accrualLink.accrual.totalAmountPayable, 2) }} {{ accrualLink.accrual.currency }}
              <div class="text-muted">{{ toFixed(accrualLink.accrual.originalTotalAmountPayable, 2) }} {{ accrualLink.accrual.originalCurrencyPayable }}</div>
            </td>
            <td>{{ toFixed(accrualLink.accrual.actualAmountPayable, 2) }} {{ accrualLink.accrual.currency }}</td>
            <td>
              {{ toFixed(accrualLink.appliedAmount, 2) }} {{ accrualLink.appliedAmountCurrency }}
              <div class="text-muted">{{ toFixed(accrualLink.originalAppliedAmount, 2) }} {{ accrualLink.originalAppliedAmountCurrency }}</div>
            </td>
            <td><pre style="background: none; font-size: inherit">{{ accrualLink.billingNumber }}</pre></td>
            <td>{{ formatWithCurrentTimezone(accrualLink.addTime) }}</td>
            <td>
              <el-link @click.stop="removeLink(accrualLink)" v-if="accrualLink.confirmTime === null">
                <i class="el-icon-delete"></i>
                Unlink
              </el-link>
              <el-link @click.stop="editAccrualLink(accrualLink)" v-if="accrualLink.confirmTime === null" class="ml-1">
                <i class="el-icon-edit"></i>
                  Edit
              </el-link>
              <el-link @click.stop="confirmAccrualLink(accrualLink)" v-if="accrualLink.confirmTime === null" class="ml-1">
                <i class="el-icon-check"></i>
                Confirm
              </el-link>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="summary.confirmTime === null">
        <el-select v-model="linkAccrualId" value-key="id" clearable>
          <el-option v-for="item in companyAccruals" :key="item.id" :label="item.accrualNumber" :value="item.id" v-if="item.deleteTime === null" :disabled="(item.chargeCodeEntity && item.chargeCodeEntity.type === 'AR') || item.confirmTime !== null">
            {{ item.accrualNumber }} / Type: {{ item.chargeCodeEntity && item.chargeCodeEntity.type ? item.chargeCodeEntity.type : 'Unspecified' }} / Charge Code: {{ item.chargeCode }} / Warehouse Code: {{ item.warehouseCode }}
          </el-option>
        </el-select>

        <el-button type="primary" class="ml-1" @click="submitLink()">Link Accrual</el-button>
      </div>
    </el-dialog>

    <el-dialog
      class="compact"
      :title="$t('Accrual Link Edit -  ' + accrualLink.accrual.accrualNumber + ' / ' + accrualLink.incomingActualPayableSummary.summaryNumber)"
      :visible.sync="isShowEdit"
      width="40%"
      v-loading="isLoading"
    >
      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Accrual Number') }}
        </b-col>
        <b-col lg="8">
          {{ accrualLink.accrual.accrualNumber }}
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Original Payable') }}
        </b-col>
        <b-col lg="8">
          {{ accrualLink.accrual.originalTotalAmountPayable }} {{ accrualLink.accrual.originalCurrencyPayable }}
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Total Payable') }}
        </b-col>
        <b-col lg="8">
          {{ accrualLink.accrual.totalAmountPayable }} {{ accrualLink.accrual.currency }}
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Actual Payable') }}
        </b-col>
        <b-col lg="8">
          {{ accrualLink.accrual.actualAmountPayable }} {{ accrualLink.accrual.currency }}
        </b-col>
      </b-row>

      <hr/>

      <h3>For this link:</h3>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('Curr. Actual Payable Applied') }}
        </b-col>
        <b-col lg="8">
          {{ accrualLinkDisplay.appliedAmount }} {{ accrualLinkDisplay.appliedAmountCurrency }} / {{ accrualLinkDisplay.originalAppliedAmount }} {{ accrualLinkDisplay.originalAppliedAmountCurrency }}
        </b-col>
      </b-row>

      <b-row class="mt-half">
        <b-col lg="4">
          {{ $t('New Actual Payable Applied') }}
        </b-col>
        <b-col lg="4">
          <el-input v-model="accrualLink.originalAppliedAmount" size="mini"/>
        </b-col>
        <b-col lg="4">
          <el-select v-model="accrualLink.originalAppliedAmountCurrency" size="mini">
            <el-option v-for="item in sunCurrencyProfileCurrencies" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </b-col>
      </b-row>

      <div style="text-align: right">
        <el-button type="primary" class="mt-1" @click="submitEditAccrualLink()">Save</el-button>
      </div>
    </el-dialog>

    <UploadModal ref="uploadView">
      <template v-slot:tip="scope">
        Only accepts xlsx files with size less than {{ $refs.uploadView ? $refs.uploadView.getMaxSizeForDisplay() : '' }}<br/>
        <a href="https://prod-kec-dashboard.s3.ap-east-1.amazonaws.com/public/Actual-Payable-Summary-Upload-Template.xlsx" target="_blank" @click.stop>Download Template</a>
      </template>
    </UploadModal>

    <el-dialog
      :title="'Confirm Incoming Actual Payable Summary Link ' + accrualLinkDisplay.incomingActualPayableSummary.summaryNumber + ' / ' + accrualLinkDisplay.accrual.accrualNumber"
      :visible.sync="isShowConfirm"
      width="50%"
    >
      <div v-if="accrualLinkDisplay.incomingActualPayableSummary.actualBillingNumber">
        <p>Accrual Billing Numbers:</p>

        <el-checkbox v-model="linkBillingNumbersAll" label="Select All"/>

        <el-checkbox-group v-model="linkBillingNumbers">
          <el-checkbox
            v-for="number in billingNumbers"
            class="mt-2" size="mini"
            :label="number"
            :value="number"
          />
        </el-checkbox-group>
      </div>

      <hr/>

      <div>
        <p>Accrual Billing Period:</p>

        <el-select v-model="accrualLink.accrualBillingPeriod">
          <el-option v-for="item in sunCurrencyProfileBillingPeriods" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>

      <div class="w-100 d-flex justify-content-end">
        <el-button type="primary" class="ml-1" @click="submitConfirmAccrualLink(row)">Confirm</el-button>
      </div>
    </el-dialog>

    <ExcelResultModal ref="excelResult"/>
    <IncomingActualPayableSummaryConfirmModal ref="confirmView"/>
    <AuditTrailModal ref="auditTrail"/>
  </div>
</template>

<script>
import { getErpProfile } from "@/utils/account-localstorage";
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import { BButton, BModal } from "bootstrap-vue";
import apis from "@/configs/apis";
import { formatWithCurrentTimezone } from "./utils/time";
import axios from "axios";
import Prompt from "@/views/erp/mixins/Prompt";
import _ from "lodash";
import { MAX_PAGE_SIZE } from "@/views/erp/utils/system";
import UploadModal from "@/views/erp/components/UploadModal.vue";
import ExcelResultModal from "@/views/erp/components/ExcelResultModal.vue";
import IncomingActualPayableSummaryConfirmModal from "@/views/erp/components/IncomingActualPayableSummaryConfirmModal.vue";
import { EventBus } from "@/views/erp/utils/event-bus";
import { showAction, toFixed } from "@/views/erp/utils/string";
import AuditTrailModal from "@/views/erp/components/AuditTrailModal.vue";
import accrual from "@/views/erp/Accrual.vue";

export default {
  mixins: [Prompt],
  components: {
    AuditTrailModal,
    IncomingActualPayableSummaryConfirmModal,
    ExcelResultModal, UploadModal,
    BButton, BModal,
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        /* { key: "selection", width: "30", align: "left"}, */
        { key: "summaryNumber", modelName: "summaryNumber", label: "Ref. ID", width: "100", filtertype: "input", sortable: true },
        { key: "systemCode", modelName: "systemCode", label: "System Code", width: "100", filtertype: "input", sortable: true },
        { key: "customerName", modelName: "customerName", label: "Customer Name", width: "100", filtertype: "input", sortable: true },
        { key: "accountCode", modelName: "accountCode", label: "Account Code", width: "100", filtertype: "input", sortable: true },
        { key: "billingPeriod", modelName: "billingPeriod", label: "Billing Period", width: "100", filtertype: "fiscal", sortable: true },
        { key: "groupingId", modelName: "groupingId", label: "Grouping ID", width: "80", filtertype: "input", sortable: true },
        { key: "vendorName", modelName: "vendorName", label: "Vendor Name", width: "100", filtertype: "input", sortable: true },
        { key: "chargeCode", modelName: "chargeCode", label: "Charge Code", width: "100", filtertype: "input", sortable: true },
        { key: "countryCode", modelName: "countryCode", label: "Destination", width: "60", filtertype: "input", sortable: true },
        { key: "originalAccrualAmount", modelName: "originalAccrualAmount", label: "Accrual AMT", width: "100", sortable: true },
        { key: "accrualBillingNumber", modelName: "accrualBillingNumber", label: "Accrual Billing No.", width: "100", filtertype: "input", sortable: true },
        { key: "actualBillingNumber", modelName: "actualBillingNumber", label: "Actual Billing No.", width: "100", filtertype: "input", sortable: true },
        { key: "referenceNumber", modelName: "referenceNumber", label: "Ref No.", width: "100", filtertype: "input", sortable: true },
        { key: "originalActualAmount", modelName: "originalActualAmount", label: "Actual AMT", width: "100", sortable: true },
        { key: "varianceAmount", modelName: "varianceAmount", label: "Variance AMT", width: "100",  sortable: true },
        { key: "unconfirmedAmount", modelName: "unconfirmedAmount", label: "Unconfirmed AMT", width: "100",  sortable: true },
        { key: "confirmTime", modelName: "confirmTime", label: "Confirm Time", width: "100",  sortable: true },
        { key: "updateTime", modelName: "updateTime", label: "Update Time", width: "100",  sortable: true },
        { key: "actions", modelName: "actions", label: "Actions", sortable: true },
      ],
      isLoading: false,
      isShowDialog: false,
      isShowLink: false,
      isShowEdit: false,
      isShowConfirm: false,
      linkAccrualId: null,
      companyAccruals: [],
      sunCurrencyProfileCurrencies: [],
      accrualLinks: [],
      action: 'CREATE',
      summary: {},
      accrualLink: {accrual: {}, incomingActualPayableSummary: {}},
      accrualLinkDisplay: {accrual: {}, incomingActualPayableSummary: {}},
      sunCurrencyProfileBillingPeriods: [],
      linkBillingNumbersAll: false,
      linkBillingNumbers: [],
    };
  },
  computed: {
    apis() {
      return apis
    },
    billingNumbers() {
      return this.accrualLinkDisplay.incomingActualPayableSummary.actualBillingNumber.split('\n').filter((item) => item !== null && item !== 'null');
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loadSunCurrencyProfileItems();

    EventBus.$on('summary-updated', () => {
      this.reload();
    });
  },
  watch: {
    linkBillingNumbersAll: function (val) {
      if (val) {
        this.linkBillingNumbers = this.billingNumbers;
      } else {
        this.linkBillingNumbers = [];
      }
    }
  },
  methods: {
    toFixed,
    showAction,
    formatWithCurrentTimezone,
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    show(item) {
      this.$router.push({
        name: 'erp-company-profile',
        params: { id: item.organizationProfileId },
        hash: '#invoice'
      })
    },

    onRowSelected(items) {
      let accountCodes = [... new Set(items.map((item) => item.accountCode))];

      if (accountCodes.length > 1) {
        let newIds = items.map((item) => item.id);
        let oldIds = this.selected.map((item) => item.id);
        let diffIds = newIds.filter(n => !oldIds.includes(n));

        for (let rowIndex = 0; rowIndex < this.$refs.advanceTable.rows.length; rowIndex += 1) {
          if (this.$refs.advanceTable.rows[rowIndex]['id'] === diffIds[0]) this.$refs.advanceTable.$refs.advanceTable.unselectRow(rowIndex);
        }

        return false;
      }

      this.selected = items;

      this.updateSelectAllState();
    },

    onSelectAllSelected() {
      if (this.$refs.selectAll.checked) {
        this.$refs.advanceTable.$refs.advanceTable.selectAllRows();
      } else {
        this.$refs.advanceTable.$refs.advanceTable.clearSelected();
      }
    },

    updateSelectAllState() {
      if (this.selected.length === 0) {
        this.$refs.selectAll.checked = false;
        this.$refs.selectAll.indeterminate = false;
      } else if (this.selected.length === this.$refs.advanceTable.rows.length) {
        this.$refs.selectAll.checked = true;
        this.$refs.selectAll.indeterminate = false;
      } else {
        this.$refs.selectAll.checked = true;
        this.$refs.selectAll.indeterminate = true;
      }
    },

    reload() {
      this.$refs.advanceTable.loadList();
    },

    create() {
      this.action = 'CREATE';
      this.isShowDialog = true;
      this.summary = {
        summaryNumber: '',
        confirmTime: null
      };
    },

    edit(row) {
      this.action = 'EDIT';
      this.isShowDialog = true;
      this.summary = row;
    },

    confirm(row) {
      this.$refs.confirmView.confirm(row);
    },

    link(row) {
      this.isShowLink = true;
      this.summary = row;
      this.loadLink();
      this.loadCompanyAccrual(row.accountCode);
    },

    submit() {
      this.isLoading = true;
      if (this.action === 'CREATE') {
        axios.post(
          apis.erpIncomingActualPayableSummary,
          this.summary
        ).then((response) => {
          this.promptInfo('Summary updated successfully')
          this.isShowDialog = false;
          this.reload();
        }).catch(e => {
          this.promptError(e);
        }).finally(() => {
          this.isLoading = false;
        });
      } else {
        axios.put(
          apis.erpIncomingActualPayableSummary + '/' + this.summary.id,
          this.summary
        ).then((response) => {
          this.promptInfo('Summary created successfully')
          this.isShowDialog = false;
          this.reload();
        }).catch(e => {
          this.promptError(e);
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },

    loadLink() {
      axios.get(
        apis.erpIncomingActualPayableSummary + '/' + this.summary.id + '/accruals'
      ).then((response) => {
        this.accrualLinks = response.data.data.data;
      }).catch(e => {
        this.promptError(e);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    submitLink() {
      axios.post(
        apis.erpIncomingActualPayableSummary + '/' + this.summary.id + '/accruals',
        {accrualId: this.linkAccrualId}
      ).then((response) => {
        this.promptInfo('Accrual linked successfully')
        this.loadLink()
      }).catch(e => {
        this.promptError(e);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    removeLink(accrualLink) {
      axios.delete(
        apis.erpIncomingActualPayableSummary + '/' + accrualLink.incomingActualPayableSummary.id + '/accruals/' + accrualLink.accrual.id,
        {accrualId: this.linkAccrualId}
      ).then((response) => {
        this.promptInfo('Accrual unlinked successfully')
        this.loadLink()
      }).catch(e => {
        this.promptError(e);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    editAccrualLink(accrualLink) {
      this.accrualLink = _.clone(accrualLink);
      this.accrualLinkDisplay = _.clone(accrualLink);

      this.isShowEdit = true;
    },

    confirmAccrualLink(accrualLink) {
      this.accrualLink = _.clone(accrualLink);
      this.accrualLinkDisplay = _.clone(accrualLink);

      this.linkBillingNumbers = [];
      this.isShowConfirm = true;
    },

    submitConfirmAccrualLink() {
      this.isLoading = true;

      axios.post(
        apis.erpIncomingActualPayableSummary + '/accrual-links/' + this.accrualLink.id + '/confirm',
        {
          billingNumbers: this.linkBillingNumbers,
          billingPeriod: this.accrualLink.accrualBillingPeriod,
        }
      ).then((response) => {
        this.promptInfo('Accrual Link confirmed successfully');
        this.isShowConfirm = false;
        this.loadLink();
      }).catch(e => {
        this.promptError(e);
      }).finally(() => {
        this.isLoading = false;
      });
    },


    submitEditAccrualLink() {
      axios.patch(
        apis.erpIncomingActualPayableSummary + '/accrual-links/' + this.accrualLink.id,
        {
          originalAppliedAmount: this.accrualLink.originalAppliedAmount,
          originalAppliedAmountCurrency: this.accrualLink.originalAppliedAmountCurrency,
          billingPeriod: this.accrualLink.accrual.billingPeriod,
        }
      ).then((response) => {
        this.promptInfo('Accrual Link updated successfully');
        this.isShowEdit = false;
        this.loadLink();
      }).catch(e => {
        this.promptError(e);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    loadSunCurrencyProfileItems() {
      axios.get(
        apis.erpGetMasterData + "/CURRENCY_PROFILE/SUN",
      ).then(response => {
        axios.get(apis.erpCurrencyProfileItems, {params: {currencyProfileId: response.value}})
          .then(response => {
            let currencies = [];
            let billingPeriods = [];
            response.data.data.data.forEach(item => {
              currencies.push(item.quoteCurrency);
              billingPeriods.push(item.billingPeriod);
            });

            this.sunCurrencyProfileCurrencies = _.uniq(currencies).sort().reverse();
            this.sunCurrencyProfileBillingPeriods = _.uniq(billingPeriods).sort().reverse();
          })
      });
    },

    loadCompanyAccrual(accountCode) {
      return axios.get(apis.erpAccruals, {params: {accountCode: accountCode, pageSize: MAX_PAGE_SIZE}})
        .then(response => {
          this.companyAccruals = response.data.data.data;
        })
    },

    openModalForUpload() {
      this.$refs.uploadView.showUpload('Upload Actual Payable Summary', 'ACTUAL_PAYABLE', {maxSize: 10485760, allowedExtension: 'xlsx'}, (form) => {
        this.isLoading = true;

        axios.post(
          apis.erpProcessActualPayableSummaries,
          form
        ).then((response) => {
          this.reload();
          this.$refs.uploadView.hideUpload();
          this.$refs.excelResult.showResult(response.data.data);
        }).catch(e => {
          this.promptError(e);
        }).finally(() => {
          this.isLoading = false;
        });
      });
    },

    exportExcel(exportType) {
      this.isLoading = true;

      let urlParams = '';
      let params = this.$refs.advanceTable.filter
      params.pageSize = MAX_PAGE_SIZE;

      for (const k in params){
        if (typeof params[k] != 'undefined') urlParams += k + "=" + params[k] + "&"
      }

      axios.get(apis.erpIncomingActualPayableSummary.substring(1) + "/export?exportType=" + exportType + '&' + urlParams, {
        responseType: 'blob'
      })
        .then((response) => {
          const anchorElement = document.createElement('a');
          document.body.appendChild(anchorElement);
          anchorElement.style.display = 'none';

          const url = window.URL.createObjectURL(response.data);

          let filename;
          if (exportType === 'EXCEL') {
            filename = 'Actual-Payable-Summary.xlsx';
          }

          anchorElement.href = url;
          anchorElement.download = filename;
          anchorElement.click();

          window.URL.revokeObjectURL(url);
        }).catch((e) => {
        this.promptError(e);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    showAudits(entity) {
      this.$refs.auditTrail.show({objectId: entity.id, className: 'com.kerrylogistics.dashboard.entities.erp.IncomingActualPayableSummary', name: 'Incoming Actual Payable Summary'});
    }
  }
};
</script>

